import { toBigInt } from "ethers"

export class BigNumber {
    value
    constructor(
        value
    ) {
        this.value = value
    }
    static from(BigInt) {
        return new BigNumber(toBigInt(BigInt))
    }
    add(_BigNumber) {
        return new BigNumber(toBigInt(this.value) + _BigNumber.value)
    }
    sub(_BigNumber) {
        return new BigNumber(toBigInt(this.value) - _BigNumber.value)
    }
    mul(_BigNumber) {
        return new BigNumber(toBigInt(this.value) * _BigNumber.value)
    }
    div(_BigNumber) {
        return new BigNumber(toBigInt(this.value) / _BigNumber.value)
    }
}