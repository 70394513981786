
import { useContext, useEffect } from "react";
import { Alert } from "../components/Alert/Alert.js";
import { Settings, Setup } from "../components/Config/Config.js";
import RoutesSolpad from "../routes/routes.js";
import AppContext from "../framework/helpers/AppContext.js";
import { Outlet } from "react-router-dom";
const PreLoader = () => {
  const {
    setnet,
    netid,
    global
  } = useContext(AppContext)

  function getNet() {
    const chain = window.location.pathname.toString().split("/")[
      1
    ]

    switch (chain) {
      case "solana":
        return "solana"
      case "tron":
        return "tron"
      default:
        return netid
    }

  }
  useEffect(() => {
    if (getNet() != netid) {
      console.log(getNet(), netid)
      setnet(getNet())

      // window.location.reload()
    } else {
      // window.location.reload()
    }
if(netid == "solana"){
  if(global){  window.solpadContext =
  window.solpadContext != undefined
      ? {
          ...window.solpadContext,
          ...global,
      }
      : global;}
}
  }, []);
  return (<>

  </>)
}
const Content = (props) => {
  const WButton = () => {
    return props.WalletButton;
  };
  const {
    setnet,
    netid
  } = useContext(AppContext)
  const pri = JSON.parse(localStorage.getItem("spad-settings"));

  return (
    <>
      <PreLoader />

      <RoutesSolpad
        WButton={WButton}
        selected={props.selected}
        openSettins={Setup}
        rpcs={props.rpcs}
        select={props.select}
        price={props.price}
        setPriority={props.setPriority}
        setCustom={props.setCustom}
        priceSol={props.priceSol}
      />
      <Settings />
      {<Alert />}
      <div
        className="wallet-button-settings"
        onClick={() => {
          Setup(
            props.selected,
            props.select,
            props.setCustom,
            props.setPriority,
            props.rpcs
          );
        }}
      >
        Priority:{" "}
        <div className="fast">
          {`${pri.priority == 0
            ? "Fast"
            : pri.priority == 1
              ? "Turbo"
              : pri.priority == 2
                ? "Ultra"
                : "Fast"
            }`}
        </div>
        <svg
          style={{
            height: "12px",
            width: "12px",
            cursor: "pointer",
            marginTop: "4px",
          }}
          onClick={() => {
            Setup(
              props.selected,
              props.select,
              props.setCustom,
              props.setPriority,
              props.rpcs
            );
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-gear-fill"
          viewBox="0 0 16 16"
        >
          <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
        </svg>
      </div>
    </>
  );
};
export default Content