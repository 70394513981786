import { deserialize } from "borsh";

import {
  getAssociatedTokenAddress,
  getAccount,
  createAssociatedTokenAccountInstruction,
  getMint,
  createApproveInstruction,
  TOKEN_PROGRAM_ID,
  TOKEN_2022_PROGRAM_ID,
} from "@solana/spl-token";
import { ethers, toBigInt } from "ethers";
import { PublicKey } from "@solana/web3.js";
import Api from "./api"
import { EVMContract, TronContract } from "./programs";
import tokenabi from "../abis/token.json"
import assets from "../assets";
import env from "../hooks/data/Config"

import { getNetwork } from "wagmi/actions";
export const getProp = (netid) => {

  switch (netid) {
    case "evm":
      try {
        return `${netid}${getNetwork().chain != undefined ? "-" + getNetwork().chain.id : "-56"}`
      } catch (error) {
        return `evm-56`
      }

    default:
      return netid

  }
}
export function returnbignumber(value, dec) {
  let a = [""];
  let e = value + "";
  if (e.toString().includes(",")) {
    a = e.toString().split(",");
    e = ethers.parseUnits(a[0] + "." + a[1], toBigInt(dec)).toString();
  } else {
    e = ethers.parseUnits(e, toBigInt(dec)).toString();
  }

  return e;
}
export function format(value) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 9
  }).format(value);
}

export function removeSpacesAndUnderscores(str) {
  return str.replace(/[\s_]/g, "");
}
export function nextpercent(accountBalancemctTB, d) {
  return parseFloat(accountBalancemctTB + "").toFixed(2);
}
export function nextblock(accountBalancemctTB, d) {
  try {
    if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
      if (accountBalancemctTB.toString().length > d) {
        const value = parseFloat(
          ethers.formatUnits(toBigInt(accountBalancemctTB), toBigInt(d))
        ).toFixed(2);

        const decimals = value.toString().split(".")[1];
        // const plus =
        return parseFloat(
          parseInt(decimals) > 0
            ? parseFloat(value)
            : parseFloat(value).toFixed(0)
        );
      } else {
        let cutin = d - accountBalancemctTB.toString().length + 2;
        return parseFloat(
          ethers.formatUnits(toBigInt(accountBalancemctTB), toBigInt(d))
        ).toFixed(cutin);
      }
    } else {
      return 0.0;
    }
  } catch (error) {
    // console.log(accountBalancemctTB, d, error)
    return 0.0;
  }

}
function getFinish(object, _bal) {
  if (object.program == env.fairSaleContract) {
    return _bal;
  }
  const tokensbuy =
    parseFloat(nextblock(object.avaliable, object.saleDecimals)) * 1;
  const prerate =
    parseFloat(nextblock(object.presale_rate, object.saleDecimals)) * 1;

  return returnbignumber(
    parseFloat((tokensbuy / prerate).toFixed(object.quoteDecimals)),
    object.quoteDecimals
  );
}
export function endedccheck(object) {
  // console.log(object)
  if (!object.ended) {
    if (
      parseInt(Date.parse(new Date()) / 1000) >= parseInt(object.startTime)
    ) {
      if (
        parseInt(Date.parse(new Date()) / 1000) < parseInt(object.endTime)
      ) {
        return "Sale live";
      } else {
        if (parseInt(object.balance) > 0) {
          if (!object.canClaim) {
            return "Finalized";
          } else {
            return "Claim Allowed";
          }
        } else {

          if (
            nextblock(object.quoteBalance, object.quoteDecimals) <
            nextblock(object.softcap, object.quoteDecimals)
          ) {
            return "Canceled";
          } else {
            return "Ended";
          }
        }

      }
    } else {
      return "coming";
    }
  } else {

    if (parseInt(getFinish(object, object.balance)) > 0) {
      if (!object.canClaim) {
        if (parseInt(object._io) == 1) return "Canceled";
        return "Finalized";
      } else {
        return "Claim Allowed";
      }
    } else {

      return "Canceled";
    }
  }
}

export function endedccheckc(object) {

  if (!object.ended) {
    if (
      parseInt(Date.parse(new Date()) / 1000) >= parseInt(object.startTime)
    ) {
      if (
        parseInt(Date.parse(new Date()) / 1000) < parseInt(object.endTime)
      ) {
        return "Live Sales";
      } else {
        if (parseInt(object.balance) > 0) {
          if (!object.canClaim) {
            return "Finalized";
          } else {
            return "Claim Allowed";
          }
        } else {

          if (
            nextblock(object.quoteBalance, object.quoteDecimals) <
            nextblock(object.softcap, object.quoteDecimals)
          ) {
            return "Canceled";
          } else {
            return "Ended";
          }
        }
        /*if (
          nextblock(object.quoteBalance, object.quoteDecimals) <
          nextblock(object.softcap, object.quoteDecimals)
        ) {
          return "Canceled";
        } else {
          return "Ended";
        }*/
      }
    } else {
      return "Coming Soon";
    }
  } else {
    if (parseInt(getFinish(object, object.balance)) > 0) {
      if (!object.canClaim) {
        return "Finalized";
      } else {
        return "Claim Allowed";
      }
    } else {
      return "Canceled";
    }
  }
}

export async function awaitForBlock() {
  const { connection } = window.solpadContext;
  const block = (await connection.getLatestBlockhash()).blockhash;
  for (let index = 0; index < 10;) {
    try {
      const blockinfon = (await connection.getLatestBlockhash()).blockhash;

      if (block != blockinfon) {
        return blockinfon;
      }
    } catch (error) {
      //console.log(error)
    }
  }
}
export function writeAccounts(defaultAccounts, abi, func) {
  const accounts = {};
  for (let index = 0; index < abi.instructions.length; index++) {
    if (abi.instructions[index].name == func) {
      for (let i = 0; i < abi.instructions[index].accounts.length; i++) {
        accounts[abi.instructions[index].accounts[i].name] =
          defaultAccounts[abi.instructions[index].accounts[i].name] != undefined
            ? defaultAccounts[abi.instructions[index].accounts[i].name]
            : undefined;
      }

      break;
    }
  }
  return accounts;
}
export async function testAccountsToken(token, accounts) {
  console.log(token, accounts)
  const { connection, publicKey } = window.solpadContext;
  const extrainstructions = [];
  //if(token.toString() == "So11111111111111111111111111111111111111112"){ return extrainstructions}
  const programId = await getTokenProgram(token);
  for (let index = 0; index < accounts.length; index++) {
    const x = await getAssociatedTokenAddress(
      new PublicKey(token),
      new PublicKey(accounts[index]),
      true,
      programId
    );
    try {
      await getAccount(connection, new PublicKey(x), false, programId);
    } catch (error) {
      const i = createAssociatedTokenAccountInstruction(
        publicKey,
        x,
        new PublicKey(accounts[index]),
        new PublicKey(token),
        programId
      );

      extrainstructions.push(i);
    }
  }

  return extrainstructions;
}
export async function approve(tkct, delegate, amount) {
  const { connection, publicKey, } = window.solpadContext;
  const approvei = createApproveInstruction(
    await getTokenAddress(tkct, publicKey), // Chave pública da conta 'a' que está aprovando
    new PublicKey(delegate), // Chave pública da conta 'b' que receberá os tokens
    new PublicKey(publicKey),
    amount // Quantidade de tokens a ser aprovada
  );
  return approvei;
}
export class Decode {
  constructor(buffer) {
    this.buffer = Uint8Array.from(buffer)
    this.type = {
      option: () => {
        throw "not set"
      },
      enum: () => {
        throw "not set"
      },
      array: () => {
        throw "not set"
      },
      set: () => {
        throw "not set"
      },
      map: () => {
        throw "not set"
      },
      struct: () => {
        throw "not set"
      },
      u8: () => { return this.decodeNumber("u8") },
      u16: () => { return this.decodeNumber("u16") },
      u32: () => { return this.decodeNumber("u32") },
      u64: () => { return this.decodeNumber("u64") },
      u128: () => { return this.decodeNumber("u128") },
      i8: () => { return this.decodeNumber("i8") },
      i16: () => { return this.decodeNumber("i16") },
      i32: () => { return this.decodeNumber("i32") },
      i64: () => { return this.decodeNumber("i64") },
      i128: () => { return this.decodeNumber("i128") },
      f32: () => { return this.decodeNumber("f32") },
      f64: () => { return this.decodeNumber("f64") },
      bool: () => { return this.decodeNumber("u8") },
      string: () => { return this.decodeString() },
      publicKey: () => { return this.decodeAddress() }
    }

  }
  decodeString() {
    try {
      const bufferlength = this.buffer.length
      const decodedLength = deserialize("u32", this.buffer.slice(0, 4));
      // console.log(decodedLength)
      const remove = decodedLength + 4
      const string = new TextDecoder('utf-8').decode(this.buffer.slice(4, decodedLength))
      // console.log(string)
      this.buffer = this.buffer.slice(remove, bufferlength)
      // console.log(this.buffer)
      return string;///new Decode(buffer.slice(remove, bufferlength))
    } catch (error) {
      console.error(error)
    }

  }
  decodeNumber(type) {
    try {
      const bufferlength = this.buffer.length
      const bits = type.includes("u") ? type.split("u")[1] : type.includes("i") ? type.split("i")[1] : type
      const bytes = parseInt(parseInt(bits) / 8)
      const number = deserialize(type, this.buffer.slice(0, bytes));
      //console.log(number)
      this.buffer = this.buffer.slice(bytes, bufferlength)
      return number;///new Decode(buffer.slice(remove, bufferlength))
    } catch (error) {
      console.error(error)
    }

  }
  decodeAddress() {
    try {
      const bufferlength = this.buffer.length
      const bytes = 32
      const result = new PublicKey(this.buffer.slice(0, bytes))
      //console.log(result)
      this.buffer = this.buffer.slice(bytes, bufferlength)
      return result;///new Decode(buffer.slice(remove, bufferlength))
    } catch (error) {
      console.error(error)
    }
  }
}
export function decodeView(abi, func, decodedBuffer) {
  for (let index = 0; index < abi.instructions.length; index++) {
    if (abi.instructions[index].name == func) {

      if (abi.instructions[index].returns.defined != undefined) {
        const SCHEMA = {
          struct: {}
        }
        const response = {}
        abi.types.forEach(types => {
          if (types.name == abi.instructions[index].returns.defined) {

            const decode = new Decode(decodedBuffer)

            types.type.fields.forEach(fields => {
              if (fields.type.vec != undefined) { } else {
                if (fields.type == "publicKey") {
                  response[fields.name] = decode.type[fields.type]().toString()
                  SCHEMA.struct[fields.name] = "u32"
                } else {
                  SCHEMA.struct[fields.name] = fields.type
                  decode.type[fields.type]()
                }

              }

            });


          }
        });
        try {
          return { ...deserialize(SCHEMA, decodedBuffer), ...response };
        } catch (error) {

        }

      } else {
        if (abi.instructions[index].returns.vec != undefined) {

          if (abi.instructions[index].returns.vec == "publicKey") {
            let list = []
            for (let index = 4; index < decodedBuffer.length; index += 32) {
              list.push(new PublicKey(decodedBuffer.slice(index, index + 32)));
            }
            return list
          }
          if (abi.instructions[index].returns.vec == "u64") {
            const SCHEMA = {
              array: {
                type: "u64"
              }
            }
            const decodedStruct = deserialize(SCHEMA, decodedBuffer);

            return decodedStruct;

          }
          if (abi.instructions[index].returns.vec == "string") {
            const SCHEMA = {
              array: {
                type: "string"
              }
            }
            const decodedStruct = deserialize(SCHEMA, decodedBuffer);

            return decodedStruct;
          }
          if (abi.instructions[index].returns.vec.defined != undefined) {
            for (let i = 0; i < abi.types.length; i++) {
              if (abi.types[i].name == abi.instructions[index].returns.vec.defined) {
                const struct = {}
                for (let e = 0; e < abi.types[i].type.fields.length; e++) {
                  if (abi.types[i].type.fields[e].type.vec != undefined) {

                  } else {
                    struct[abi.types[i].type.fields[e].name] = abi.types[i].type.fields[e].type
                  }

                }

                const SCHEMA = {
                  array: {
                    type: {
                      struct: struct
                    }
                  }
                }
                const decodedStruct = deserialize(SCHEMA, decodedBuffer);

                return decodedStruct;
              }
            }

          }
        } else {
          if (abi.instructions[index].returns == "publicKey") {
            return new PublicKey(decodedBuffer.slice(0, 32))
          } else {
            const SCHEMA = abi.instructions[index].returns
            const decodedStruct = deserialize(SCHEMA, decodedBuffer);

            return decodedStruct;
          }

        }
      }

      break;
    }

  }
}
export async function getTokenAddress(mint, to) {
  const { connection, publicKey, } = window.solpadContext;
  const programId = await getTokenProgram(mint);
  const x = await getAssociatedTokenAddress(
    new PublicKey(mint),
    new PublicKey(to),
    true,
    programId
  );
  return x;
}
export async function getToken(mint, net = "solana") {
  switch (net) {
    case "solana":
      const { connection, publicKey } = window.solpadContext;
      try {
        //const datatoken = await Api.use("https://solpad-nodeapi.azurewebsites.net/get").token.get("/" + mint)
        // const datatoken = await Api.use("https://solpad-nodeapi.azurewebsites.net/get").token.post({
        //   mint: mint
        // })
        const datatoken = await Api.token.get("/" + mint)
        return await datatoken;
      } catch (error) {
        const programId = await getTokenProgram(mint);
        const data = await getMint(
          connection,
          new PublicKey(mint),
          true,
          programId
        );
        data.name = undefined;
        data.symbol = undefined;
        return data;
      }
      break
    case "tron":
      const token = new TronContract(mint, mint, env.token.abi)
      const data = {}
      data.name = await token.methods.name.view();
      data.symbol = await token.methods.symbol.view()
      data.decimals = parseInt(await token.methods.decimals.view())
      data.supply = await token.methods.totalSupply.view()
      data.address = mint
      try {
        data.getBalance = async (address) => {
          return await token.methods.balanceOf.view(address)
        }
      } catch (error) {

      }

      //  console.log(data)
      return data
    default:
      const tk = new EVMContract(mint, mint, env.token.abi)

      //  console.log(data)
      return {
        name: await tk.methods.name.view(),
        symbol: await tk.methods.symbol.view(),
        decimals: parseInt(await tk.methods.decimals.view()),
        supply: await tk.methods.totalSupply.view(),
        address: mint,
        getBalance: async (address) => {
          return await tk.methods.balanceOf.view(address)
        }
      }
  }

}
export function programNetwork(program) {
  const solana = {
    name: "solana",
    symbol: "SOL",
    icon: assets.images.logoSmallSolana,
    explorer: "https://solscan.io/account/",
    dextools: "https://dexscreener.com/solana/",
    swaplink: "https://raydium.io/swap/?outputMint=sol&inputMint=",
    ownerFac: "HsMy8p8S4aovaKCn5HnfRHWWhGosLjARUpRvDGojZ1zi",
    token: ""
  }
  const tron = {
    name: "tron",
    symbol: "TRX",
    icon: assets.images.logoSmallTron,
    explorer: "https://tronscan.org/#/address/",
    dextools: "https://dexscreener.com/tron/",
    swaplink: "https://sun.io/?lang=en-US#/sun_swap/v2?t0=T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb&type=swap&from=v3&t1="
    ,
    ownerFac: "TUR7zJYj73fEcB9t7Fa4AGnmvWedRqNg4U",//"TDipxvmAdaHec5NkFcyAfPamBb2KRo7mzJ",
    token: "TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR"
  }
  const bsc = {
    name: "bsc",
    symbol: "BNB",
    icon: assets.images.logoSmallEVM,
    explorer: "https://bscscan.com/address/",
    dextools: "https://dexscreener.com/bsc/",
    swaplink: "https://pancakeswap.finance/?inputCurrency=BNB&outputCurrency="
    ,
    ownerFac: "0xfE7B2472B61472DD3Ecf8ab1C6256C9609394748",//"TDipxvmAdaHec5NkFcyAfPamBb2KRo7mzJ",
    token:
      //"0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd" 
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
  }
  const programs = {
    "5x1ZUJiA2YwEEVavMAu6cafxH4LsxP3ceKG6Ccg98WWQ": solana,
    "AvdDZ3qG5b4JcVViY6MZUDhbmqCMepNfoTEyUkqaaSY1": solana,
    "5ic31ZwHCvL9vg8b4er22TDLaDyLakEv167CJNTsyXhU": solana,
    "THZDAMxmJSuTePNmkMzYK5hQoUxqC9jGLc": tron,
    "TRecaqEuzBsmwW74rK1LJfMu9earrCyQoa": tron,
    "TQcu6wpbEN27gkVsrxET4H8gGhcLRhFh2f": tron,
    "TBVge3LErN3C3Nk8ja1SFJpGQd8uf7QNDo": tron,
    "TEhsrzyxy12gYkBZThoPKNSknGwbkto8kX": tron,
    "8ySRF1aUq5CDVxGhrh62s3uW5v9kZxw4xeK3uaB284KB": solana,
    "0xc1468758567EBBF368f1A51E1FA8e88197b860eF": bsc,
    "0x4717B709301Aef8FCaFAa494c1370DF01D75E5D3": bsc,
    "0x4ca37cB8B2B7bb0cD692c77C61AcbD971D91e2Cb": bsc,
    "0x29dc484c6375D800A6E9F84043C43a6FE1a3BF55": bsc,
  }

  return programs[program] != undefined ? programs[program] : solana
}
export async function getTokenProgram(mint) {
  const { connection,  } = window.solpadContext;
  try {
    const data = await getMint(
      connection,
      new PublicKey(mint),
      undefined,
      TOKEN_PROGRAM_ID
    );
    //alert("isNormal")
    return TOKEN_PROGRAM_ID;
  } catch (error) {
    try {
      const data = await getMint(
        connection,
        new PublicKey(mint),
        undefined,
        TOKEN_2022_PROGRAM_ID
      );
      //  alert("is2022")
      return TOKEN_2022_PROGRAM_ID;
    } catch (error) {
      return TOKEN_PROGRAM_ID;
    }
  }
}
export async function balanceOf(uk, acc) {
  //  //console.log(uk, acc)
  const { connection } = window.solpadContext;

  if (uk == "So11111111111111111111111111111111111111112") {
    const retorno = await connection.getBalance(new PublicKey(acc));
    return retorno;
  } else {
    const info = await connection.getTokenAccountBalance(
      await getAssociatedTokenAddress(uk, new PublicKey(acc))
    );
    return info.value.uiAmount;
  }
}
export async function loadSaleMetaData(addr) {
  const g = await Api.sale.metadata.get("/" + addr)
  return g;
}
export async function updateSaleMetadata(add, data) {
  const {
    LogoUrl,
    Website,
    Facebook,
    Twitter,
    Github,
    Telegram,
    Discord,
    Instagram,
    Reddit,
    YoutubeVideo,
    Description,
  } = data;
  const all = {
    auth: JSON.stringify(add.pda.secretKey),
    editpad: {
      key: add.metadatapda,
      description: Description,
      urls: {
        image: LogoUrl,
        website: Website,
        github: Github,
        facebook: Facebook,
        twitter: Twitter,
        instagram: Instagram,
        telegram: Telegram,
        discord: Discord,
        reddit: Reddit,
        banner: YoutubeVideo,
      },
    },
  }
  await Api.sale.metadata.post(all.editpad)
  return all;
};
export function quote(network, currency) {
  switch (network) {
    case "solana":
      return (currency === 0
        ? " SOL"
        : currency === 1
          ? " USDC"
          : currency === 2
            ? " USDT"
            : " SOL");
    case "tron":
      return (currency === 0
        ? " TRX"
        : currency === 1
          ? " USDC"
          : currency === 2
            ? " USDT"
            : " TRX");
    case "bsc":
      return (currency === 0
        ? " BNB"
        : currency === 1
          ? " USDC"
          : currency === 2
            ? " USDT"
            : " BNB");
    default:

      break;
  }
}

export function formatNumber(number) {
  if (number == 0) { return 0 }
  if (number < 1) { return number.toLocaleString("en-US"); }
  //  
  const simplifiedNumber = simplifyNumber(number);
  const formattedNumber = simplifiedNumber.toLocaleString("en-US");
  return formattedNumber;
}

export function simplifyNumber(number) {
  const suffixes = ["", "K", "M", "B", "T"];
  const suffixIndex = Math.floor(Math.log10(Math.abs(number)) / 3);
  const simplifiedNumber = (number / Math.pow(10, suffixIndex * 3)).toFixed(
    1
  );
  return simplifiedNumber + suffixes[suffixIndex];
}