import "../style.scss";
import assets from "../../../../../assets/index";
import Button from "../../../../../components/Launchpad/Button.js";

const Rewards = ({ lock, setlock, apr, price, stake, harvest, priceSOL, priceSOLUSD, amounts, reward,
  TOKENA, TOKENB }) => {
  return (
    <>
      <div className="row align-items-center">
      
      <div className="tab-content__item">
            <img src={`${assets.images.coinSolanaSmall}`} alt="" />
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENB}</div>
              <div className="tab-content__item--chain__value">{reward[2]}</div>
            </div>
            <Button func={async () => { await harvest(2) }} text={"Claim"} className="next" />
          </div>
      </div>
      {/*<div className="row align-items-center">
        <div className="col col-4">
          <div className="tab-title">1 Month | Paper hand</div>
        </div>
        <div className="col col-8 tab-content">
          <div className="tab-content__item">
            <img src={`${assets.images.coinSolanaSmall}`} alt="" />
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENB}</div>
              <div className="tab-content__item--chain__value">{reward[1]}</div>
            </div>
            <Button func={async () => { await harvest(1) }} text={"Claim"} className="next" />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col col-4">
          <div className="tab-title">3 Month | Paper hand</div>
        </div>
        <div className="col col-8 tab-content">
          <div className="tab-content__item">
            <img src={`${assets.images.coinSolanaSmall}`} alt="" />
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENB}</div>
              <div className="tab-content__item--chain__value">{reward[3]}</div>
            </div>
            <Button func={async () => { await harvest(3) }} text={"Claim"} className="next" />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col col-4">
          <div className="tab-title">6 Month | Paper hand</div>
        </div>
        <div className="col col-8 tab-content">
          <div className="tab-content__item">
            <img src={`${assets.images.coinSolanaSmall}`} alt="" />
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENB}</div>
              <div className="tab-content__item--chain__value">{reward[6]}</div>
            </div>
            <Button func={async () => { await harvest(6) }} text={"Claim"} className="next" />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col col-4">
          <div className="tab-title">12 Month | Paper hand</div>
        </div>
        <div className="col col-8 tab-content">
          <div className="tab-content__item">
            <img src={`${assets.images.coinSolanaSmall}`} alt="" />
            <div className="tab-content__item--chain">
              <div className="tab-content__item--chain__title">${TOKENB}</div>
              <div className="tab-content__item--chain__value">{reward[12]}</div>
            </div>
            <Button func={async () => { await harvest(12) }} text={"Claim"} className="next" />
          </div>
        </div>
  </div>*/}
    </>
  );
};

export default Rewards;
