// Client
import {
    PublicKey,
    Keypair,
    SystemProgram,
    Transaction,
    TransactionInstruction,
    SYSVAR_RENT_PUBKEY,
    SYSVAR_CLOCK_PUBKEY,
    ComputeBudgetProgram,
} from "@solana/web3.js";
import {
    AccountLayout,
    TOKEN_PROGRAM_ID,
    createInitializeAccountInstruction,
    createAssociatedTokenAccountInstruction,
    ASSOCIATED_TOKEN_PROGRAM_ID,
    getMint,
    createTransferInstruction,
    createSyncNativeInstruction,
} from "@solana/spl-token";

import { BN } from "@coral-xyz/anchor";
import { Liquidity, MAINNET_PROGRAM_ID, MARKET_STATE_LAYOUT_V2, MARKET_STATE_LAYOUT_V3, MarketV2, RENT_PROGRAM_ID, SYSTEM_PROGRAM_ID, generatePubKey, struct, u64, u8 } from "@raydium-io/raydium-sdk";
import { getTokenAddress } from "./utils";
import { sha256 } from '@noble/hashes/sha256'

const rayProgram = new PublicKey(
    MAINNET_PROGRAM_ID.OPENBOOK_MARKET
    // "srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX" //mainet
    //"EoTcMgcDRTJVZDMZWBoU6rhYHZfkNTVEAfz3uUJRcYGj"
); // mainNet srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX
const feeReceiver = new PublicKey(
    // "3XMrhbv989VxAMi3DErLV9eJht1pHppW5LbKxe9fkEFR"
    "7YttLkHDoNj9wyDur5pM1ejNaAvT9X4eqaYcHQqtj2G5"
); // new PublicKey("7YttLkHDoNj9wyDur5pM1ejNaAvT9X4eqaYcHQqtj2G5");
const ammv4 = new PublicKey(
    // "HWy1jotHpo6UqeQxx49dpYYdQB8wj9Qk9MdxwjLvDHB8"
    "675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8"// mainNet

);
// mainNet 675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8
export async function checkMarketId(pg) {
    const marketBufferInfo = await pg.connection.getAccountInfo(pg.marketId);
    if (!marketBufferInfo) throw "MarketId Invalid";
}
async function checkCreated(pg, lptoken) {
    try {
        await getMint(pg.connection, lptoken)
        return false
    } catch (error) {
        return true
    }

}
// function generatePubKey({
//     fromPublicKey,
//     programId = import_spl_token3.TOKEN_PROGRAM_ID
//   }) {
//     const seed = import_web311.Keypair.generate().publicKey.toBase58().slice(0, 32);
//     const publicKey2 = createWithSeed(fromPublicKey, seed, programId);
//     return { publicKey: publicKey2, seed };
//   }
//   function createWithSeed(fromPublicKey, seed, programId) {
//     const buffer = Buffer.concat([fromPublicKey.toBuffer(), Buffer.from(seed), programId.toBuffer()]);
//     const publicKeyBytes = sha256(buffer);
//     return new import_web311.PublicKey(publicKeyBytes);
//   }
export function generateMarketId({
    fromPublicKey,
    factorPublicKey,
    programId = rayProgram,
}) {
    // return generatePubKey({ fromPublicKey: fromPublicKey, programId: programId })
    const seed = factorPublicKey.toBase58().slice(0, 32)
    const buffer = Buffer.concat([fromPublicKey.toBuffer(), Buffer.from(seed), programId.toBuffer()])
    const publicKeyBytes = sha256(buffer)
    const publicKey = new PublicKey(publicKeyBytes)
    console.log(
        fromPublicKey.toString(),
        factorPublicKey.toString(),
        publicKey.toString()
    )
    return { publicKey, seed }
}

export async function getFunctionAccounts(
    baseMint,
    quoteMint,
    baseDecimals,
    quoteDecimals,
    pg
) {
    let useMarketId = pg.marketId != undefined


    const market = useMarketId
        ?
        {
            publicKey: new PublicKey(pg.marketId)
        }
        :
        generateMarketId({
            fromPublicKey: new PublicKey(pg.wallet.publicKey),
            factorPublicKey: new PublicKey(pg.program.storage),
            programId: rayProgram
        })
    //  console.log(market.publicKey.toString())
    const marketBufferInfo = await pg.connection.getAccountInfo(market.publicKey);

    if (useMarketId) {
        if (!marketBufferInfo) throw "MarketId Invalid";
    } else {
        if (!marketBufferInfo) {
            //   useMarketId = false
            //throw "MarketId Invalid"
        } else {
            useMarketId = true
        };

    }

    //  const data = MARKET_STATE_LAYOUT_V3.decode(marketBufferInfo.data)
    //  console.log(JSON.stringify(data))
    const {
        id,
        lpMint,
        programId,
        authority,
        nonce,
        baseVault,
        quoteVault,
        openOrders,
        targetOrders,
        configId
    } = Liquidity.getAssociatedPoolKeys({
        version: 4,
        marketVersion: 3,
        marketId: market.publicKey,
        baseMint: new PublicKey(baseMint),
        quoteMint: new PublicKey(quoteMint),
        baseDecimals: baseDecimals,
        quoteDecimals: quoteDecimals,
        programId: ammv4,
        marketProgramId: rayProgram
    })

    const [userLpVault, _g_] = PublicKey.findProgramAddressSync(
        [
            // new PublicKey("66gtGDFy1Fhsw1uffKhbYgiTKRmga25SAw5N32v3tedd").toBuffer(),
            pg.wallet.publicKey.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            lpMint.toBuffer(),
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID
    );
    // console.log(lpMint.toString(), userLpVault.toString())
    // const createPoolornot = await checkCreated(pg, lpMint)
    const dataLayout = struct([
        u8('instruction'),
        u8('nonce'),
        u64('openTime'),
        u64('pcAmount'),
        u64('coinAmount')
    ])
    const finalizeData = await pg.program.methods["finalizeData"].view({})
    // console.log(finalizeData)
    const data = Buffer.alloc(dataLayout.span)
    dataLayout.encode({
        instruction: 1,
        nonce,
        openTime: pg.OpenTime,
        coinAmount: new BN(finalizeData.return_0),
        pcAmount: new BN(finalizeData.return_1)
    }, data)

    const keys = [
        { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: ASSOCIATED_TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: SYSTEM_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: RENT_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: id, isSigner: false, isWritable: true },
        { pubkey: authority, isSigner: false, isWritable: false },
        { pubkey: openOrders, isSigner: false, isWritable: true },
        { pubkey: lpMint, isSigner: false, isWritable: true },
        { pubkey: new PublicKey(baseMint), isSigner: false, isWritable: false },
        { pubkey: new PublicKey(quoteMint), isSigner: false, isWritable: false },
        { pubkey: baseVault, isSigner: false, isWritable: true },
        { pubkey: quoteVault, isSigner: false, isWritable: true },
        { pubkey: targetOrders, isSigner: false, isWritable: true },
        { pubkey: configId, isSigner: false, isWritable: false },
        { pubkey: feeReceiver, isSigner: false, isWritable: true },
        { pubkey: rayProgram, isSigner: false, isWritable: false },
        { pubkey: market.publicKey, isSigner: false, isWritable: false },
        { pubkey: pg.wallet.publicKey, isSigner: true, isWritable: true },
        { pubkey: await getTokenAddress(baseMint, pg.wallet.publicKey), isSigner: false, isWritable: true },
        { pubkey: await getTokenAddress(quoteMint, pg.wallet.publicKey), isSigner: false, isWritable: true },
        { pubkey: userLpVault, isSigner: false, isWritable: true },
    ]

    // Tx For Create Pool 
    const ins = new TransactionInstruction({
        keys,
        programId,
        data,
    })
    const finalizeIns = await pg.program.methods["finalize"].instruction(
        [],
        [],
        {
            ...pg.finalizeAccounts,
        }
    )
    if (useMarketId) return {
        maintx: [
            finalizeIns,
            ins
        ],
        extras: [
            //...createPoolTransaction
        ]
    }
    //create Market Id
    // console.log(MARKET_STATE_LAYOUT_V2.span)
    const defaultRents = true ? {
        a: MARKET_STATE_LAYOUT_V2.span,
        b: 764,
        c: 11308,
        d: 14524,
    } : {
        a: MARKET_STATE_LAYOUT_V2.span,
        b: 5120 + 12,
        c: 262144 + 12,
        d: 65536 + 12,
    }

    const minimumOrderSize = 1;
    const tickSize = 0.000001;
    const baseLotSize = new BN(Math.round(10 ** baseDecimals * minimumOrderSize))
    const quoteLotSize = new BN(Math.round(minimumOrderSize * 10 ** quoteDecimals * tickSize))
    const feeRateBps = 0
    const quoteDustThreshold = new BN(100)
    //console.log(pg)
    // const balance = await pg.connection.getBalance(pg.wallet.publicKey);
    // const market = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const requestQueue = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const eventQueue = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const bids = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const asks = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const gbaseVault = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: TOKEN_PROGRAM_ID })
    const gquoteVault = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: TOKEN_PROGRAM_ID })

    function getVaultOwnerAndNonce() {
        const vaultSignerNonce = new BN(0)
        // eslint-disable-next-line no-constant-condition
        while (true) {
            try {
                const vaultOwner = PublicKey.createProgramAddressSync(
                    [market.publicKey.toBuffer(), vaultSignerNonce.toArrayLike(Buffer, 'le', 8)],
                    rayProgram,
                )
                return { vaultOwner, vaultSignerNonce }
            } catch (e) {
                vaultSignerNonce.iaddn(1)
                if (vaultSignerNonce.gt(new BN(25555))) throw Error('find vault owner error')
            }
        }
    }
    const { vaultOwner, vaultSignerNonce } = getVaultOwnerAndNonce()

    // Iniciar as contas na rede da solana!

    const a = {
        connection: pg.connection,
        wallet: pg.wallet.publicKey,
        marketInfo: {
            programId: rayProgram,
            id: market,
            baseMint: new PublicKey(baseMint),
            quoteMint: new PublicKey(quoteMint),
            baseVault: gbaseVault,
            quoteVault: gquoteVault,
            vaultOwner,
            requestQueue,
            eventQueue,
            bids,
            asks,

            feeRateBps,
            quoteDustThreshold,
            vaultSignerNonce,
            baseLotSize,
            quoteLotSize,

            //  requestQueueSpacce: 10000,
            //  eventQueueSpacce: 2978,
            //  orderbookQueueSpacce: 909
        },
    }
    const accountLamports = await pg.connection.getMinimumBalanceForRentExemption(
        165
    );
    let instc = [
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: gbaseVault.seed,
            newAccountPubkey: gbaseVault.publicKey,
            lamports: accountLamports,
            space: 165,
            programId: TOKEN_PROGRAM_ID,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: gquoteVault.seed,
            newAccountPubkey: gquoteVault.publicKey,
            lamports: accountLamports,
            space: 165,
            programId: TOKEN_PROGRAM_ID,
        }),


        createInitializeAccountInstruction(gbaseVault.publicKey, new PublicKey(baseMint), vaultOwner),
        createInitializeAccountInstruction(gquoteVault.publicKey, new PublicKey(quoteMint), vaultOwner),

    ];
    let instc2 = [

        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: market.seed,
            newAccountPubkey: market.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.a),
            space: defaultRents.a,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: requestQueue.seed,
            newAccountPubkey: requestQueue.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.b),
            space: defaultRents.b,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: eventQueue.seed,
            newAccountPubkey: eventQueue.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(
                defaultRents.c
            ),
            space: defaultRents.c,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: bids.seed,
            newAccountPubkey: bids.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.d),
            space: defaultRents.d,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: asks.seed,
            newAccountPubkey: asks.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.d),
            space: defaultRents.d,
            programId: rayProgram,
        }),
        MarketV2.initializeMarketInstruction({
            programId: a.marketInfo.programId,
            marketInfo: {
                id: a.marketInfo.id.publicKey,
                requestQueue: a.marketInfo.requestQueue.publicKey,
                eventQueue: a.marketInfo.eventQueue.publicKey,
                bids: a.marketInfo.bids.publicKey,
                asks: a.marketInfo.asks.publicKey,
                baseVault: a.marketInfo.baseVault.publicKey,
                quoteVault: a.marketInfo.quoteVault.publicKey,
                baseMint: a.marketInfo.baseMint,
                quoteMint: a.marketInfo.quoteMint,
                baseLotSize: a.marketInfo.baseLotSize,
                quoteLotSize: a.marketInfo.quoteLotSize,
                feeRateBps: a.marketInfo.feeRateBps,
                vaultSignerNonce: a.marketInfo.vaultSignerNonce,
                quoteDustThreshold: a.marketInfo.quoteDustThreshold,
            },

        }),
    ];
    //  console.log(instc)
    //console.log(instc2)
    const transaction = new Transaction();
    transaction.add(...instc);
    const transaction2 = new Transaction();
    transaction2.add(...instc2);

    return {
        maintx: [
            finalizeIns,
            ins
        ],
        extras: [
            transaction,
            transaction2,
            // createPoolTransaction
        ]
    }

}
export async function getFunctionAccountsv2(
    baseMint,
    quoteMint,
    baseDecimals,
    quoteDecimals,
    pg
) {
    const useMarketId = pg.marketId != undefined
    const market = useMarketId
        ?
        {
            publicKey: new PublicKey(pg.marketId)
        }
        :
        generatePubKey({
            fromPublicKey: pg.wallet.publicKey,
            programId: rayProgram
        })
    const marketBufferInfo = await pg.connection.getAccountInfo(market.publicKey);
    if (!marketBufferInfo) throw "MarketId Invalid";

    const {
        id,
        lpMint,
        programId,
        authority,
        nonce,
        baseVault,
        quoteVault,
        openOrders,
        targetOrders,
        configId
    } = Liquidity.getAssociatedPoolKeys({
        version: 4,
        marketVersion: 3,
        marketId: market.publicKey,
        baseMint: new PublicKey(baseMint),
        quoteMint: new PublicKey(quoteMint),
        baseDecimals: baseDecimals,
        quoteDecimals: quoteDecimals,
        programId: ammv4,
        marketProgramId: rayProgram
    })

    const [userLpVault, _g_] = PublicKey.findProgramAddressSync(
        [
            pg.manager.publicKey.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            lpMint.toBuffer(),
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID
    );
    console.log(lpMint.toString())
    const createPoolornot = await checkCreated(pg, lpMint)
    const dataLayout = struct([
        u8('instruction'),
        u8('nonce'),
        u64('openTime'),
        u64('pcAmount'),
        u64('coinAmount')
    ])
    const data = Buffer.alloc(dataLayout.span)
    dataLayout.encode({
        instruction: 1,
        nonce,
        openTime: pg.OpenTime,
        coinAmount: new BN(10000000),
        pcAmount: new BN(10000000)
    }, data)

    const keys = [
        { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: ASSOCIATED_TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: SYSTEM_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: RENT_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: id, isSigner: false, isWritable: true },
        { pubkey: authority, isSigner: false, isWritable: false },
        { pubkey: openOrders, isSigner: false, isWritable: true },
        { pubkey: lpMint, isSigner: false, isWritable: true },
        { pubkey: new PublicKey(baseMint), isSigner: false, isWritable: false },
        { pubkey: new PublicKey(quoteMint), isSigner: false, isWritable: false },
        { pubkey: baseVault, isSigner: false, isWritable: true },
        { pubkey: quoteVault, isSigner: false, isWritable: true },
        { pubkey: targetOrders, isSigner: false, isWritable: true },
        { pubkey: configId, isSigner: false, isWritable: false },
        { pubkey: feeReceiver, isSigner: false, isWritable: true },
        { pubkey: rayProgram, isSigner: false, isWritable: false },
        { pubkey: market.publicKey, isSigner: false, isWritable: false },
        { pubkey: pg.manager.publicKey, isSigner: true, isWritable: true },
        { pubkey: await getTokenAddress(baseMint, pg.manager.publicKey), isSigner: false, isWritable: true },
        { pubkey: await getTokenAddress(quoteMint, pg.manager.publicKey), isSigner: false, isWritable: true },
        { pubkey: userLpVault, isSigner: false, isWritable: true },
    ]

    // Tx For Create Pool 
    const ins = new TransactionInstruction({
        keys,
        programId,
        data,
    })
    const createPoolTransaction = createPoolornot ? [
        new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: pg.wallet.publicKey,
                toPubkey: pg.manager.publicKey,
                lamports: 400000000// 10000000
            }),
            SystemProgram.transfer({
                fromPubkey: pg.wallet.publicKey,
                toPubkey: await getTokenAddress(new PublicKey(quoteMint), pg.manager.publicKey),
                lamports: 10000000// 10000000
            }),
            createSyncNativeInstruction(
                await getTokenAddress(new PublicKey(quoteMint), pg.manager.publicKey)
            ),
            createTransferInstruction(
                await getTokenAddress(new PublicKey(baseMint), pg.wallet.publicKey),
                await getTokenAddress(new PublicKey(baseMint), pg.manager.publicKey),
                pg.wallet.publicKey,
                10000000
            ),
            ins
        )
    ] : []
    //console.log(JSON.stringify(pg.FinalizeInstruction))
    if (useMarketId) return {
        maintx: [
            /* SystemProgram.createAccount({
                 fromPubkey: pg.wallet.publicKey,
                 newAccountPubkey: pg.manager.publicKey,
                 lamports: 10000000,
                 space: 1000,
                 programId: new PublicKey(pg.wallet.publicKey),
             }),*/
            //  pg.FinalizeInstruction,
            //ins
        ],
        extras: [
            ...createPoolTransaction
        ]
    }
    //create Market Id

    const defaultRents = false ? {
        a: 388,
        b: 5120 + 12,
        c: 262144 + 12,
        d: 65536 + 12,
    } : {
        a: MARKET_STATE_LAYOUT_V2.span,
        b: 5120 + 12,
        c: 262144 + 12,
        d: 65536 + 12,
    }

    const minimumOrderSize = 0.1;
    const tickSize = 0.01;
    const baseLotSize = new BN(Math.round(10 ** baseDecimals * minimumOrderSize))
    const quoteLotSize = new BN(Math.round(minimumOrderSize * 10 ** quoteDecimals * tickSize))
    const feeRateBps = 0
    const quoteDustThreshold = new BN(100)
    //console.log(pg)
    // const balance = await pg.connection.getBalance(pg.wallet.publicKey);
    // const market = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const requestQueue = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const eventQueue = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const bids = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const asks = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const gbaseVault = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: TOKEN_PROGRAM_ID })
    const gquoteVault = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: TOKEN_PROGRAM_ID })

    function getVaultOwnerAndNonce() {
        const vaultSignerNonce = new BN(0)
        // eslint-disable-next-line no-constant-condition
        while (true) {
            try {
                const vaultOwner = PublicKey.createProgramAddressSync(
                    [market.publicKey.toBuffer(), vaultSignerNonce.toArrayLike(Buffer, 'le', 8)],
                    rayProgram,
                )
                return { vaultOwner, vaultSignerNonce }
            } catch (e) {
                vaultSignerNonce.iaddn(1)
                if (vaultSignerNonce.gt(new BN(25555))) throw Error('find vault owner error')
            }
        }
    }
    const { vaultOwner, vaultSignerNonce } = getVaultOwnerAndNonce()

    // Iniciar as contas na rede da solana!

    const a = {
        connection: pg.connection,
        wallet: pg.wallet.publicKey,
        marketInfo: {
            programId: rayProgram,
            id: market,
            baseMint: new PublicKey(baseMint),
            quoteMint: new PublicKey(quoteMint),
            baseVault: gbaseVault,
            quoteVault: gquoteVault,
            vaultOwner,
            requestQueue,
            eventQueue,
            bids,
            asks,

            feeRateBps,
            quoteDustThreshold,
            vaultSignerNonce,
            baseLotSize,
            quoteLotSize,

            //  requestQueueSpacce: 10000,
            //  eventQueueSpacce: 2978,
            //  orderbookQueueSpacce: 909
        },
    }
    const accountLamports = await pg.connection.getMinimumBalanceForRentExemption(
        165
    );
    let instc = [
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: gbaseVault.seed,
            newAccountPubkey: gbaseVault.publicKey,
            lamports: accountLamports,
            space: 165,
            programId: TOKEN_PROGRAM_ID,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: gquoteVault.seed,
            newAccountPubkey: gquoteVault.publicKey,
            lamports: accountLamports,
            space: 165,
            programId: TOKEN_PROGRAM_ID,
        }),
        createInitializeAccountInstruction(gbaseVault.publicKey, new PublicKey(baseMint), vaultOwner),
        createInitializeAccountInstruction(gquoteVault.publicKey, new PublicKey(quoteMint), vaultOwner),
    ];
    let instc2 = [

        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: market.seed,
            newAccountPubkey: market.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.a),
            space: defaultRents.a,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: requestQueue.seed,
            newAccountPubkey: requestQueue.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.b),
            space: defaultRents.b,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: eventQueue.seed,
            newAccountPubkey: eventQueue.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(
                defaultRents.c
            ),
            space: defaultRents.c,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: bids.seed,
            newAccountPubkey: bids.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.d),
            space: defaultRents.d,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: asks.seed,
            newAccountPubkey: asks.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.d),
            space: defaultRents.d,
            programId: rayProgram,
        }),
        MarketV2.initializeMarketInstruction({
            programId: a.marketInfo.programId,
            marketInfo: {
                id: a.marketInfo.id.publicKey,
                requestQueue: a.marketInfo.requestQueue.publicKey,
                eventQueue: a.marketInfo.eventQueue.publicKey,
                bids: a.marketInfo.bids.publicKey,
                asks: a.marketInfo.asks.publicKey,
                baseVault: a.marketInfo.baseVault.publicKey,
                quoteVault: a.marketInfo.quoteVault.publicKey,
                baseMint: a.marketInfo.baseMint,
                quoteMint: a.marketInfo.quoteMint,
                baseLotSize: a.marketInfo.baseLotSize,
                quoteLotSize: a.marketInfo.quoteLotSize,
                feeRateBps: a.marketInfo.feeRateBps,
                vaultSignerNonce: a.marketInfo.vaultSignerNonce,
                quoteDustThreshold: a.marketInfo.quoteDustThreshold,
            },

        }),
    ];
    console.log(instc)
    console.log(instc2)
    const transaction = new Transaction();
    transaction.add(...instc);
    const transaction2 = new Transaction();
    transaction2.add(...instc2);

    return {
        maintx: [

        ],
        extras: [
            transaction,
            transaction2,
            createPoolTransaction
        ]
    }

}
export async function getFunctionAccountsv1(
    baseMint,
    quoteMint,
    baseDecimals,
    quoteDecimals,
    pg,
    _marketId
) {
    function getVaultOwnerAndNonce() {
        const vaultSignerNonce = new BN(0)
        // eslint-disable-next-line no-constant-condition
        while (true) {
            try {
                const vaultOwner = PublicKey.createProgramAddressSync(
                    [market.publicKey.toBuffer(), vaultSignerNonce.toArrayLike(Buffer, 'le', 8)],
                    rayProgram,
                )
                return { vaultOwner, vaultSignerNonce }
            } catch (e) {
                vaultSignerNonce.iaddn(1)
                if (vaultSignerNonce.gt(new BN(25555))) throw Error('find vault owner error')
            }
        }
    }
    if (_marketId != undefined) {
        const marketBufferInfo = await pg.connection.getAccountInfo(_marketId);
        if (!marketBufferInfo) return;


        //   const { baseVault, quoteVault } = MARKET_STATE_LAYOUT_V3.decode(marketBufferInfo.data)

        // console.log(JSON.stringify(MARKET_STATE_LAYOUT_V3.decode(marketBufferInfo.data)))

        const {
            // base
            id,
            //   baseMint,
            //   quoteMint,
            lpMint,
            // baseDecimals,
            // quoteDecimals,
            // lpDecimals: baseDecimals,
            // version
            version,
            programId,
            // keys
            authority,
            nonce,
            baseVault,
            quoteVault,
            lpVault,
            openOrders,
            targetOrders,
            withdrawQueue,
            // market version
            marketVersion,
            marketProgramId,
            // market keys
            //  marketId,
            marketAuthority,
            lookupTableAccount,
            configId
        } = Liquidity.getAssociatedPoolKeys({
            version: 4,
            marketVersion: 3,
            marketId: _marketId,
            baseMint: new PublicKey(baseMint),
            quoteMint: new PublicKey(quoteMint),
            baseDecimals: baseDecimals,
            quoteDecimals: quoteDecimals,
            programId: ammv4,
            marketProgramId: rayProgram
        })

        const [userLpVault, _g_] = PublicKey.findProgramAddressSync(
            [
                new PublicKey("66gtGDFy1Fhsw1uffKhbYgiTKRmga25SAw5N32v3tedd").toBuffer(),
                TOKEN_PROGRAM_ID.toBuffer(),
                lpMint.toBuffer(),
            ],
            ASSOCIATED_TOKEN_PROGRAM_ID
        );
        /*  console.log(JSON.stringify({
              userLpVault,
              lpVault,
              ubase: await getTokenAddress(baseMint, pg.wallet.publicKey),
              qbase: await getTokenAddress(quoteMint, pg.wallet.publicKey)
          }))*/
        const dataLayout = struct([u8('instruction'), u8('nonce'), u64('openTime'), u64('pcAmount'), u64('coinAmount')])


        const data = Buffer.alloc(dataLayout.span)
        dataLayout.encode({
            instruction: 1,
            nonce,
            openTime: new BN(parseInt(Date.now() / 1000) + 600),
            coinAmount: new BN(10000000),
            pcAmount: new BN(10000000)
        }, data)
        console.log(data)
        /* 
           const keys = [
               { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
               { pubkey: ASSOCIATED_TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
               { pubkey: SYSTEM_PROGRAM_ID, isSigner: false, isWritable: false },
               { pubkey: RENT_PROGRAM_ID, isSigner: false, isWritable: false },
               { pubkey: id, isSigner: false, isWritable: true },
               { pubkey: authority, isSigner: false, isWritable: false },
               { pubkey: openOrders, isSigner: false, isWritable: true },
               { pubkey: lpMint, isSigner: false, isWritable: true },
               { pubkey: new PublicKey(baseMint), isSigner: false, isWritable: false },
               { pubkey: new PublicKey(quoteMint), isSigner: false, isWritable: false },
               { pubkey: baseVault, isSigner: false, isWritable: true },
               { pubkey: quoteVault, isSigner: false, isWritable: true },
               { pubkey: targetOrders, isSigner: false, isWritable: true },
               { pubkey: configId, isSigner: false, isWritable: false },
               { pubkey: feeReceiver, isSigner: false, isWritable: true },
               { pubkey: rayProgram, isSigner: false, isWritable: false },
               { pubkey: _marketId, isSigner: false, isWritable: false },
               { pubkey: pg.wallet.publicKey, isSigner: true, isWritable: true },
               { pubkey: await getTokenAddress(baseMint, pg.wallet.publicKey), isSigner: false, isWritable: true },
               { pubkey: await getTokenAddress(quoteMint, pg.wallet.publicKey), isSigner: false, isWritable: true },
               { pubkey: userLpVault, isSigner: false, isWritable: true },
           ]
   
        
           const ins = new TransactionInstruction({
               keys,
               programId,
               data,
           })*/
        return {
            inputs: [
                data,//new BN(0),
                nonce,
                new BN(parseInt(Date.now() / 1000) + 600)
            ],
            accounts: {
                rayprogram: rayProgram,
                sysvar: RENT_PROGRAM_ID,
                atp: ASSOCIATED_TOKEN_PROGRAM_ID,
                ammConfigId: configId,
                ammAuthority: authority,
                ammTargetOrders: targetOrders,
                marketInfoId: _marketId,
                feeDestination: feeReceiver,
                ammId: id,
                ammOpenOrders: openOrders,
                lpMint: lpMint,
                coinVault: baseVault,
                pcVault: quoteVault,
                userLpVault: userLpVault,
                ammv4: ammv4
            },
            txs: [
                // new Transaction().add(ins)
            ]
        }
    }

    const defaultRents = false ? {
        a: 388,
        b: 5120 + 12,
        c: 262144 + 12,
        d: 65536 + 12,
    } : {
        a: MARKET_STATE_LAYOUT_V2.span,
        b: 5120 + 12,
        c: 262144 + 12,
        d: 65536 + 12,
    }

    const minimumOrderSize = 1;
    const tickSize = 0.1;
    const baseLotSize = new BN(Math.round(10 ** baseDecimals * minimumOrderSize))
    const quoteLotSize = new BN(Math.round(minimumOrderSize * 10 ** quoteDecimals * tickSize))

    //console.log(pg)
    // const balance = await pg.connection.getBalance(pg.wallet.publicKey);
    const market = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const requestQueue = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const eventQueue = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const bids = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const asks = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const baseVault = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: TOKEN_PROGRAM_ID })
    const quoteVault = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: TOKEN_PROGRAM_ID })
    const feeRateBps = 0
    const quoteDustThreshold = new BN(100)

    const { vaultOwner, vaultSignerNonce } = getVaultOwnerAndNonce()
    const lpDecimals = baseDecimals;
    const {
        // base
        id,
        //   baseMint,
        //   quoteMint,
        lpMint,
        // baseDecimals,
        // quoteDecimals,
        // lpDecimals: baseDecimals,
        // version
        version,
        programId,
        // keys
        authority,
        nonce,
        // baseVault,
        // quoteVault,
        lpVault,
        openOrders,
        targetOrders,
        withdrawQueue,
        // market version
        marketVersion,
        marketProgramId,
        // market keys
        // marketId,
        marketAuthority,
        lookupTableAccount,
        configId
    } = Liquidity.getAssociatedPoolKeys({
        version: 4,
        marketVersion: 3,
        marketId: market.publicKey,
        baseMint: new PublicKey(baseMint),
        quoteMint: new PublicKey(quoteMint),
        baseDecimals: baseDecimals,
        quoteDecimals: quoteDecimals,
        programId: ammv4,
        marketProgramId: rayProgram
    })

    const [userLpVault, _g_] = PublicKey.findProgramAddressSync(
        [
            pg.wallet.publicKey.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            lpMint.toBuffer(),
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID
    );

    // Iniciar as contas na rede da solana!
    //console.log(baseVault.wallet.toBase58(), " | ", baseVault2.toBase58());

    const a = {
        connection: pg.connection,
        wallet: pg.wallet.publicKey,
        marketInfo: {
            programId: rayProgram,
            id: market,
            baseMint: new PublicKey(baseMint),
            quoteMint: new PublicKey(quoteMint),
            baseVault,
            quoteVault,
            vaultOwner,
            requestQueue,
            eventQueue,
            bids,
            asks,

            feeRateBps,
            quoteDustThreshold,
            vaultSignerNonce,
            baseLotSize,
            quoteLotSize,

            //  requestQueueSpacce: 10000,
            //  eventQueueSpacce: 2978,
            //  orderbookQueueSpacce: 909
        },
    }
    const accountLamports = await pg.connection.getMinimumBalanceForRentExemption(
        165
    );
    let instc = [
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: baseVault.seed,
            newAccountPubkey: baseVault.publicKey,
            lamports: accountLamports,
            space: 165,
            programId: TOKEN_PROGRAM_ID,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: quoteVault.seed,
            newAccountPubkey: quoteVault.publicKey,
            lamports: accountLamports,
            space: 165,
            programId: TOKEN_PROGRAM_ID,
        }),
        createInitializeAccountInstruction(baseVault.publicKey, new PublicKey(baseMint), vaultOwner),
        createInitializeAccountInstruction(quoteVault.publicKey, new PublicKey(quoteMint), vaultOwner),
    ];
    let instc2 = [

        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: market.seed,
            newAccountPubkey: market.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.a),
            space: defaultRents.a,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: requestQueue.seed,
            newAccountPubkey: requestQueue.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.b),
            space: defaultRents.b,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: eventQueue.seed,
            newAccountPubkey: eventQueue.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(
                defaultRents.c
            ),
            space: defaultRents.c,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: bids.seed,
            newAccountPubkey: bids.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.d),
            space: defaultRents.d,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: asks.seed,
            newAccountPubkey: asks.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.d),
            space: defaultRents.d,
            programId: rayProgram,
        }),
        MarketV2.initializeMarketInstruction({
            programId: a.marketInfo.programId,
            marketInfo: {
                id: a.marketInfo.id.publicKey,
                requestQueue: a.marketInfo.requestQueue.publicKey,
                eventQueue: a.marketInfo.eventQueue.publicKey,
                bids: a.marketInfo.bids.publicKey,
                asks: a.marketInfo.asks.publicKey,
                baseVault: a.marketInfo.baseVault.publicKey,
                quoteVault: a.marketInfo.quoteVault.publicKey,
                baseMint: a.marketInfo.baseMint,
                quoteMint: a.marketInfo.quoteMint,
                baseLotSize: a.marketInfo.baseLotSize,
                quoteLotSize: a.marketInfo.quoteLotSize,
                feeRateBps: a.marketInfo.feeRateBps,
                vaultSignerNonce: a.marketInfo.vaultSignerNonce,
                quoteDustThreshold: a.marketInfo.quoteDustThreshold,
            },

        }),
    ];
    console.log(instc)
    console.log(instc2)
    const transaction = new Transaction();
    transaction.add(...instc);
    const transaction2 = new Transaction();
    transaction2.add(...instc2);
    /*
       const acc = await getTokenAccounts(pg.wallet.publicKey, pg.connection);
      const quoteAccount = acc.find(
           (da) => da.account.data.parsed.info.mint == quoteMint
       ).pubkey;
       const baseAccount = acc.find(
           (da) => da.account.data.parsed.info.mint == baseMint
       ).pubkey;
   */
    //console.log(JSON.stringify({quoteAccount, baseAccount}))
    //  const baseVault = this.getAssociatedBaseVault({ programId, marketId })
    //  const quoteVault = this.getAssociatedQuoteVault({ programId, marketId })
    return {
        inputs: [
            new BN(vaultSignerNonce),
            new BN(nonce),
            new BN(parseInt(Date.now() / 1000) + 600)
        ],
        accounts: {
            rayprogram: rayProgram,
            sysvar: SYSVAR_RENT_PUBKEY,
            atp: ASSOCIATED_TOKEN_PROGRAM_ID,
            ammConfigId: configId,
            ammAuthority: authority,

            ammTargetOrders: targetOrders,
            marketInfoId: market.publicKey,
            //  requestQueue: requestQueue.publicKey,
            //  eventQueue: eventQueue.publicKey,
            //  bids: bids.publicKey,

            // asks: asks.publicKey,
            feeDestination: feeReceiver,
            ammId: id,

            // vaultOwner: vaultOwner,
            //  baseVault: baseVault.publicKey,
            //  quoteVault: quoteVault.publicKey,


            ammOpenOrders: openOrders,
            lpMint: lpMint,
            coinVault: Liquidity.getAssociatedBaseVault({ programId: ammv4, marketId: market.publicKey }),
            pcVault: Liquidity.getAssociatedQuoteVault({ programId: ammv4, marketId: market.publicKey }),
            userLpVault: userLpVault,

            ammv4: ammv4,
            // quoteMint: quoteMint,

            //  baseMint: baseMint,


            // sysvar: SYSVAR_CLOCK_PUBKEY,
            // tokenProgram: TOKEN_PROGRAM_ID,
            //  quoteTokenAccount: quoteAccount,
            //  baseTokenAccount: baseAccount,
            //


            // tokenProgramAssociate: ASSOCIATED_TOKEN_PROGRAM_ID,


            // system_program: SystemProgram.programId,




        },
        txs: [
            transaction,
            transaction2
        ]
    }

}
export async function getCreatePoolAccounts(
    baseMint,
    quoteMint,
    baseDecimals,
    quoteDecimals,
    pg
) {
    const defaultRents = false ? {
        a: 388,
        b: 5120 + 12,
        c: 262144 + 12,
        d: 65536 + 12,
    } : {
        a: MARKET_STATE_LAYOUT_V2.span,
        b: 512 + 12,
        c: 26214 + 12,
        d: 6553 + 12,
    }

    const market = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const requestQueue = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const eventQueue = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const bids = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const asks = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: rayProgram })
    const baseVault = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: TOKEN_PROGRAM_ID })
    const quoteVault = generatePubKey({ fromPublicKey: pg.wallet.publicKey, programId: TOKEN_PROGRAM_ID })

    function getVaultOwnerAndNonce() {
        const vaultSignerNonce = new BN(0)
        // eslint-disable-next-line no-constant-condition
        while (true) {
            try {
                const vaultOwner = PublicKey.createProgramAddressSync(
                    [market.publicKey.toBuffer(), vaultSignerNonce.toArrayLike(Buffer, 'le', 8)],
                    rayProgram,
                )
                return { vaultOwner, vaultSignerNonce }
            } catch (e) {
                vaultSignerNonce.iaddn(1)
                if (vaultSignerNonce.gt(new BN(25555))) throw Error('find vault owner error')
            }
        }
    }
    const { vaultOwner, vaultSignerNonce } = getVaultOwnerAndNonce()
    const lpDecimals = baseDecimals;
    const {
        // base
        id,
        //   baseMint,
        //   quoteMint,
        lpMint,
        // baseDecimals,
        // quoteDecimals,
        // lpDecimals: baseDecimals,
        // version
        version,
        programId,
        // keys
        authority,
        nonce,
        // baseVault,
        // quoteVault,
        lpVault,
        openOrders,
        targetOrders,
        withdrawQueue,
        // market version
        marketVersion,
        marketProgramId,
        // market keys
        marketId,
        marketAuthority,
        lookupTableAccount,
        configId
    } = Liquidity.getAssociatedPoolKeys({
        version: 4,
        marketVersion: 3,
        marketId: market.publicKey,
        baseMint: new PublicKey(baseMint),
        quoteMint: new PublicKey(quoteMint),
        baseDecimals: baseDecimals,
        quoteDecimals: quoteDecimals,
        programId: ammv4,
        marketProgramId: rayProgram
    })

    const [userLpVault, _g_] = PublicKey.findProgramAddressSync(
        [
            pg.wallet.publicKey.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            lpMint.toBuffer(),
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID
    );

    // Iniciar as contas na rede da solana!
    //console.log(baseVault.wallet.toBase58(), " | ", baseVault2.toBase58());

    /* const a = {
         connection: pg.connection,
         wallet: pg.wallet.publicKey,
         marketInfo: {
             programId: rayProgram,
             id: market,
             baseMint: new PublicKey(baseMint),
             quoteMint: new PublicKey(quoteMint),
             baseVault,
             quoteVault,
             vaultOwner,
             requestQueue,
             eventQueue,
             bids,
             asks,
 
             feeRateBps,
             quoteDustThreshold,
             vaultSignerNonce,
             baseLotSize,
             quoteLotSize,
 
             //  requestQueueSpacce: 10000,
             //  eventQueueSpacce: 2978,
             //  orderbookQueueSpacce: 909
         },
     }*/
    const accountLamports = await pg.connection.getMinimumBalanceForRentExemption(
        165
    );
    let instc = [
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: baseVault.seed,
            newAccountPubkey: baseVault.publicKey,
            lamports: accountLamports,
            space: 165,
            programId: TOKEN_PROGRAM_ID,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: quoteVault.seed,
            newAccountPubkey: quoteVault.publicKey,
            lamports: accountLamports,
            space: 165,
            programId: TOKEN_PROGRAM_ID,
        }),
        createInitializeAccountInstruction(baseVault.publicKey, new PublicKey(baseMint), vaultOwner),
        createInitializeAccountInstruction(quoteVault.publicKey, new PublicKey(quoteMint), vaultOwner),
    ];
    let instc2 = [

        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: market.seed,
            newAccountPubkey: market.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.a),
            space: defaultRents.a,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: requestQueue.seed,
            newAccountPubkey: requestQueue.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.b),
            space: defaultRents.b,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: eventQueue.seed,
            newAccountPubkey: eventQueue.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(
                defaultRents.c
            ),
            space: defaultRents.c,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: bids.seed,
            newAccountPubkey: bids.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.d),
            space: defaultRents.d,
            programId: rayProgram,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: pg.wallet.publicKey,
            basePubkey: pg.wallet.publicKey,
            seed: asks.seed,
            newAccountPubkey: asks.publicKey,
            lamports: await pg.connection.getMinimumBalanceForRentExemption(defaultRents.d),
            space: defaultRents.d,
            programId: rayProgram,
        }),
        /*  MarketV2.initializeMarketInstruction({
              programId: a.marketInfo.programId,
              marketInfo: {
                  id: a.marketInfo.id.publicKey,
                  requestQueue: a.marketInfo.requestQueue.publicKey,
                  eventQueue: a.marketInfo.eventQueue.publicKey,
                  bids: a.marketInfo.bids.publicKey,
                  asks: a.marketInfo.asks.publicKey,
                  baseVault: a.marketInfo.baseVault.publicKey,
                  quoteVault: a.marketInfo.quoteVault.publicKey,
                  baseMint: a.marketInfo.baseMint,
                  quoteMint: a.marketInfo.quoteMint,
                  baseLotSize: a.marketInfo.baseLotSize,
                  quoteLotSize: a.marketInfo.quoteLotSize,
                  feeRateBps: a.marketInfo.feeRateBps,
                  vaultSignerNonce: a.marketInfo.vaultSignerNonce,
                  quoteDustThreshold: a.marketInfo.quoteDustThreshold,
              },
  
          }),*/
    ];
    console.log(instc)
    console.log(instc2)
    const transaction = new Transaction();
    transaction.add(...instc);
    const transaction2 = new Transaction();
    transaction2.add(...instc2);

    return {
        inputs: [
            new BN(vaultSignerNonce),
            new BN(nonce),
            new BN(parseInt(Date.now() / 1000) + 1000)
        ],
        accounts: {
            rayprogram: rayProgram,
            sysvar: SYSVAR_RENT_PUBKEY,
            atp: ASSOCIATED_TOKEN_PROGRAM_ID,
            ammConfigId: configId,
            ammAuthority: authority,

            ammTargetOrders: targetOrders,
            marketInfoId: market.publicKey,
            requestQueue: requestQueue.publicKey,
            eventQueue: eventQueue.publicKey,
            bids: bids.publicKey,

            asks: asks.publicKey,
            vaultOwner: vaultOwner,
            baseVault: baseVault.publicKey,
            quoteVault: quoteVault.publicKey,
            ammId: id,

            ammOpenOrders: openOrders,
            lpMint: lpMint,
            coinVault: Liquidity.getAssociatedBaseVault({ programId: ammv4, marketId: market.publicKey }),
            pcVault: Liquidity.getAssociatedBaseVault({ programId: ammv4, marketId: market.publicKey }),
            userLpVault: userLpVault,

            ammv4: ammv4,

            // feeDestination: feeReceiver,
        },
        txs: [
            transaction,
            transaction2
        ]
    }

}
export default getFunctionAccounts
